export default class ApiClient {
  static #host = process.env.API_URL;
  static #routes = {
    findOrCreateIbvConnection: this.#host + "/api/v1/ibv/connections",
    createConsumer: this.#host + "/api/v1/consumers",
    getWidgetConfig: this.#host + "/api/v1/widget_configuration/get_config",
    pullIbvData: this.#host + "/api/v1/ibv/data_collection/pull_ibv_data",
    createProductRequest: this.#host + "/api/v1/ibv/requests",
    getProductRequest: this.#host + "/api/v1/ibv/requests/:id",
    findIbvConnection: this.#host + "/api/v1/ibv/connections/find",
    updateIbvConnection: this.#host + "/api/v1/ibv/connections/:id",
    getIbvSequence: this.#host + "/api/v2/widget_configuration/ibv_sequence",
    getConsumerInfo:
      this.#host + "/api/v1/consumers/consumer_info/:client_consumer_id",
    logBusinessEvent: this.#host + "/api/v1/business_events",
    logVendorWidgetEvent: this.#host + "/api/v1/vendor_widget_events",
    searchInstitutions: this.#host + "/api/v1/institutions/?q=:searchValue",
    topInstitutions: this.#host + "/api/v1/institutions/top_institutions",
    getConnectionById: this.#host + "/api/v1/ibv/connections/:id",
    setNotificationSettings: this.#host + "/api/v1/notifications_settings",
    updateNotificationSettings: this.#host + "/api/v1/notifications_settings/:id",
    getNotificationSettings: this.#host + "/api/v1/notifications_settings",
    getTermsAndConditions: this.#host + "/api/v1/terms_and_conditions",
    updateConsents: this.#host + "/api/v1/consents",
  };

  static async pullIbvData(params, access_token) {
    return await this.#performRequest(
      this.#routes.pullIbvData,
      "POST",
      JSON.stringify(params),
      access_token
    );
  }

  static async setNotificationSettings(params, access_token) {
    return await this.#performRequest(
      this.#routes.setNotificationSettings,
      "POST",
      JSON.stringify(params),
      access_token
    );
  }

  static async updateNotificationSettings(params, id, access_token) {
    let route = this.#routes.updateNotificationSettings.replace(
      ":id",
      id
    );

    return await this.#performRequest(
      route,
      "PUT",
      JSON.stringify(params),
      access_token
    );
  }

  static async getNotificationSettings(access_token) {
    return await this.#performRequest(
      this.#routes.getNotificationSettings,
      "GET",
      null,
      access_token
    );
  }

  static async getConnectionById(connection_id, access_token) {
    let route = this.#routes.getConnectionById.replace(":id", connection_id);

    return await this.#performRequest(route, "GET", null, access_token);
  }

  static async findOrCreateIbvConnection(params, access_token) {
    return await this.#performRequest(
      this.#routes.findOrCreateIbvConnection,
      "POST",
      JSON.stringify(params),
      access_token
    );
  }

  static async findIbvConnection(params, access_token) {
    return await this.#performRequest(
      this.#routes.findIbvConnection,
      "POST",
      JSON.stringify(params),
      access_token
    );
  }

  static async updateIbvConnection(connection_id, params, access_token) {
    let route = this.#routes.updateIbvConnection.replace(":id", connection_id);

    return await this.#performRequest(
      route,
      "PUT",
      JSON.stringify(params),
      access_token
    );
  }

  static async getConsumerInfo(client_consumer_id, access_token) {
    let route = this.#routes.getConsumerInfo.replace(
      ":client_consumer_id",
      client_consumer_id
    );

    return await this.#performRequest(route, "GET", null, access_token);
  }

  static async searchInstitutions(searchValue, access_token) {
    let route = this.#routes.searchInstitutions.replace(
      ":searchValue",
      searchValue
    );

    return await this.#performRequest(route, "GET", null, access_token);
  }

  static async topInstitutions(access_token) {
    return await this.#performRequest(
      this.#routes.topInstitutions,
      "GET",
      null,
      access_token
    );
  }

  static async createConsumer(params, access_token) {
    return await this.#performRequest(
      this.#routes.createConsumer,
      "POST",
      JSON.stringify(params),
      access_token
    );
  }

  static async getWidgetConfig(params = {}, access_token) {
    return await this.#performRequest(
      this.#routes.getWidgetConfig,
      "POST",
      JSON.stringify(params),
      access_token
    );
  }

  static async createProductRequest(params, access_token) {
    return await this.#performRequest(
      this.#routes.createProductRequest,
      "POST",
      JSON.stringify({ ...params, try_to_reuse_product_request: true }),
      access_token
    );
  }

  static async getProductRequest(productRequestId, params = {}, access_token) {
    let route = this.#routes.getProductRequest.replace(":id", productRequestId);
    route = route + "?interactive=true";

    return await this.#performRequest(
      route,
      "GET",
      JSON.stringify(params),
      access_token
    );
  }

  static async isProductRequestFrozen(productRequestId, access_token, params = {}) {
    let route = this.#routes.getProductRequest.replace(":id", productRequestId);
    route = route + "?interactive=true&include=freeze";

    return await this.#performRequest(
      route,
      "GET",
      JSON.stringify(params),
      access_token
    );
  }

  static async getTermsAndConditions(access_token, params = {}) {
    return await this.#performRequest(
      this.#routes.getTermsAndConditions,
      "GET",
      JSON.stringify(params),
      access_token
    );
  }

  static async updateConsents(access_token, params = {}) {
    return await this.#performRequest(
      this.#routes.updateConsents,
      "PUT",
      JSON.stringify(params),
      access_token
    );
  }

  static async getIbvSequence(params, access_token) {
    return await this.#performRequest(
      this.#routes.getIbvSequence,
      "POST",
      JSON.stringify(params),
      access_token
    );
  }

  static async logBusinessEvent(params, access_token, keepalive = false) {
    return await this.#performRequest(
      this.#routes.logBusinessEvent,
      "POST",
      JSON.stringify(params),
      access_token,
      keepalive
    );
  }

  static async logVendorWidgetEvent(params, access_token) {
    return await this.#performRequest(
      this.#routes.logVendorWidgetEvent,
      "POST",
      JSON.stringify(params),
      access_token
    );
  }

  static async #performRequest(endpoint, method, body, access_token, keepalive = false) {
    const headers = {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods":
        "GET, POST, DELETE, PUT, PATCH, OPTIONS, HEAD",
      "Access-Control-Expose-Headers": "",
      "Access-Control-Max-Age": "7200",
      Origin: this.#host,
      "Content-Type": "application/json;charset=utf-8",
      Authorization: "Bearer " + access_token,
    };
    const withBody = ["POST", "PATCH", "PUT"];
    let requestConfig;

    if (withBody.indexOf(method) !== -1) {
      requestConfig = { method: method, headers: headers, body: body, keepalive };
    } else {
      requestConfig = { method: method, headers: headers, keepalive };
    }

    const response = await fetch(endpoint, requestConfig);

    return await this.#processResponse(response);
  }

  static async #processResponse(response) {
    if (response.status === 204) {
      return;
    }

    const parsedResponse = await response.json();

    if (response.status === 200 || response.status === 202) {
      return parsedResponse;
    }

    throw new Error("Got not success status from server", {
      cause: {
        statusCode: response.status,
        errorDetails: parsedResponse,
      },
    });
  }
}

export const ApiErrorMessagesMapper = (errorObject, additionalParams = {}) => {
  if (!errorObject.cause) {
    if (typeof errorObject === "object") {
      return { message: JSON.stringify(errorObject), ...additionalParams };
    }

    return { message: "Internal vendor error", ...additionalParams };
  }

  const errorMessage = errorObject?.cause?.errorDetails?.message

  switch (errorObject?.cause?.statusCode) {
    case 500:
      return {
        message: errorMessage || "Internal server error",
        ...additionalParams
      };
    case 401:
      return {
        message: errorMessage || "Auth token has been expired",
        ...additionalParams
      };
    case 404:
      return { message: errorMessage || "Not found", ...additionalParams };
    case 400:
      return {
        message: errorMessage || "Bad response",
        details: errorObject.cause.errorDetails,
        ...additionalParams,
      };
    default:
      return {
        message: errorMessage || "Internal vendor error",
        details: errorObject.cause,
        ...additionalParams,
      };
  }
};

export const loginFailureCallbackPayload = (errorType, message) => {
  return { errorType: errorType || "other", message };
}
