import {
  BUSINESS_WIDGET_CONNECT_CLICKED,
  BUSINESS_WIDGET_RETRY_CLICKED,
  BUSINESS_WIDGET_LANDING_DISPLAYED,
  BUSINESS_WIDGET_REUSE_INSTITUTION_CLICKED,
  BUSINESS_WIDGET_NEW_INSTITUTION_CLICKED,
  BUSINESS_WIDGET_RETRY_DISPLAYED,
  BUSINESS_WIDGET_INSTITUTION_PRESELECTED,
  BUSINESS_WIDGET_BANK_SELECTION_OPENED,
  BUSINESS_WIDGET_BANK_SELECTION_SELECTED,
  BUSINESS_WIDGET_LOGIN_FAILED,
  BUSINESS_WIDGET_LOGIN_SUCCEEDED,
  BUSINESS_WIDGET_SEARCH_CLICKED,
  BUSINESS_WIDGET_WINDOW_CLOSED,
  BUSINESS_WIDGET_OUTDATED_USER_AGENT_DISPLAYED
} from "../constants/business";

export const logBusinessNewConnectionClicked = (params) => {
  return {
    type: BUSINESS_WIDGET_NEW_INSTITUTION_CLICKED,
    payload: params,
  };
};

export const logBusinessReuseClicked = (params) => {
  return {
    type: BUSINESS_WIDGET_REUSE_INSTITUTION_CLICKED,
    payload: params,
  };
};

export const logBusinessInstitutionPreselected = (params) => {
  return {
    type: BUSINESS_WIDGET_INSTITUTION_PRESELECTED,
    payload: params,
  };
};

export const logBusinessDisplayed = (params) => {
  return {
    type: BUSINESS_WIDGET_LANDING_DISPLAYED,
    payload: params,
  };
};

export const logBusinessClicked = (params) => {
  return {
    type: BUSINESS_WIDGET_CONNECT_CLICKED,
    payload: params,
  };
};

export const logBusinessBankSelectionOpened = (params) => {
  return {
    type: BUSINESS_WIDGET_BANK_SELECTION_OPENED,
    payload: params,
  };
};

export const logBusinessBankSelectionSelected = (params) => {
  return {
    type: BUSINESS_WIDGET_BANK_SELECTION_SELECTED,
    payload: params,
  };
};

export const logBusinessLoginFailed = (params) => {
  return {
    type: BUSINESS_WIDGET_LOGIN_FAILED,
    payload: params,
  };
};

export const logBusinessLoginSucceeded = (params) => {
  return {
    type: BUSINESS_WIDGET_LOGIN_SUCCEEDED,
    payload: params,
  };
};

export const logBusinessRetryDisplayed = (params) => {
  return {
    type: BUSINESS_WIDGET_RETRY_DISPLAYED,
    payload: params,
  };
};

export const logBusinessRetryClicked = (params) => {
  return {
    type: BUSINESS_WIDGET_RETRY_CLICKED,
    payload: params,
  };
};

export const logBusinessSearchClicked = (params) => {
  return {
    type: BUSINESS_WIDGET_SEARCH_CLICKED,
    payload: params,
  };
};

export const logBusinessWindowClosed = (params) => {
  return {
    type: BUSINESS_WIDGET_WINDOW_CLOSED,
    payload: params,
  };
};
export const logBusinessOutdatedUserAgentDisplayed = (params) => {
  return {
    type: BUSINESS_WIDGET_OUTDATED_USER_AGENT_DISPLAYED,
    payload: params,
  };
};
